import React from "react";

class MahjongTable extends React.Component {

    render() {
        if (Object.keys(this.props.players).length > 0) {

            const players = this.props.players

            return (
                <div className="Table-visual">
                    <div className="PlayerInfo">
                        name: {players.player1.name} points: {players.player1.points}
                    </div>
                    <div className="PlayerInfo">
                        name: {players.player2.name} points: {players.player2.points}
                    </div>
                    <div className="PlayerInfo">
                        name: {players.player3.name} points: {players.player3.points}
                    </div>
                    <div className="PlayerInfo">
                        name: {players.player4.name} points: {players.player4.points}
                    </div>
                </div>
            )
        }
        return (<p>Loading data...</p>)
    }
}

export default MahjongTable