import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import Api from "./components/Api"
import Home from "./components/Home"
import Mahjong from './components/Mahjong';

import React from 'react';
import './App.css';

const router = createBrowserRouter([
  {
    path: '/api',
    element: <Api />
  },
  {
    path: '/mahjong',
    element: <Mahjong />
  },
  {
    path: '/',
    element: <Home />
  }
]);

function App() {
  return (
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  );
}

export default App;
