import React from "react";

class GameForm extends React.Component {

    render() {

        return (
            <>
            <p>
                {this.props.players.dealer}
            </p>

                <form id="gameForm" onSubmit={(e) => {e.preventDefault(); this.processWin()}}>
                    Winner[s]:
                    <div className="checkBox-box">
                        <div className="formRow">
                            <input type="checkbox" className="winnerCheck" id="winner-1" name="winnerBox" value={this.props.players.player1.name} onChange={() => this.toggleHidden("winner-1-number")}></input>
                            <label htmlFor="winner-1">{this.props.players.player1.name}</label>
                            <input type="number" id="winner-1-number" placeholder="0" hidden></input>  <br />
                        </div>
                        <div className="formRow">
                            <input type="checkbox" className="winnerCheck" id="winner-2" name="winnerBox" value={this.props.players.player2.name} onChange={() => this.toggleHidden("winner-2-number")}></input>
                            <label htmlFor="winner-2">{this.props.players.player2.name}</label>
                            <input type="number" id="winner-2-number" placeholder="0" hidden></input>  <br />
                        </div>
                        <div className="formRow">
                            <input type="checkbox" className="winnerCheck" id="winner-3" name="winnerBox" value={this.props.players.player3.name} onChange={() => this.toggleHidden("winner-3-number")}></input>
                            <label htmlFor="winner-3">{this.props.players.player3.name}</label>
                            <input type="number" id="winner-3-number" placeholder="0" hidden></input>  <br />
                        </div>
                        <div className="formRow">
                            <input type="checkbox" className="winnerCheck" id="winner-4" name="winnerBox" value={this.props.players.player4.name} onChange={() => this.toggleHidden("winner-4-number")}></input>
                            <label htmlFor="winner-4">{this.props.players.player4.name}</label>
                            <input type="number" id="winner-4-number" placeholder="0" hidden></input>  <br />
                        </div>
                    </div>

                    <div className="radio-container">
                        win type:
                        <input type="radio" id="ron" name="winType" value="0"></input>
                        <label htmlFor="ron">ron</label>
                        <input type="radio" id="tsumo" name="winType" value="1"></input>
                        <label htmlFor="tsumo">tsumo</label>
                        <input type="radio" id="draw" name="winType" value="2"></input>
                        <label htmlFor="draw">draw</label>
                    </div>

                    <div className="radio-container">
                        target:
                        <input type="radio" id="loser-1" name="deal-in" value="1"></input>
                        <label htmlFor="loser-1">{this.props.players.player1.name}</label>
                        <input type="radio" id="loser-2" name="deal-in" value="2"></input>
                        <label htmlFor="loser-2">{this.props.players.player2.name}</label>
                        <input type="radio" id="loser-3" name="deal-in" value="3"></input>
                        <label htmlFor="loser-3">{this.props.players.player3.name}</label>
                        <input type="radio" id="loser-4" name="deal-in" value="4"></input>
                        <label htmlFor="loser-4">{this.props.players.player4.name}</label>
                    </div>

                    <button>aloo</button>
                </form>
            </>
        )
    }

    toggleHidden(target) {
        let targetBox = document.getElementById(target)
        targetBox.hidden = !targetBox.hidden
    }

    processWin() {

        const gameform = document.getElementById("gameForm");
        const inputs = gameform.getElementsByTagName("input");

        const winnersArray = [];
        const pointsArray= [];

        const checkedWin = document.querySelector("input[name=winType]:checked")
        const checkedDealIn = document.querySelector("input[name=deal-in]:checked")

        if (checkedWin == null) {
            alert("wintype not checked")
            return;
        }

        if (checkedDealIn == null) {
            alert("deal-in player not checked")
            return;
        }

        for (let ind = 0; ind<inputs.length; ind++) {
            if (inputs[ind].type === "checkbox" && inputs[ind].checked) {winnersArray.push(inputs[ind].value)}
        }

        for (let ind = 0; ind < winnersArray.length; ind++) {
            let points = parseInt(document.getElementById("winner-"+winnersArray[ind]+"-number").value)

            if (isNaN(points) || points < 1) {
                alert("point number error!");
                return;
            }
            pointsArray[ind] = points
        }

        if (winnersArray.length !== pointsArray.length) {
            alert("array match error!")
            return;
        }

        winnersArray.forEach(element => {
            console.log(element);
        });

        pointsArray.forEach(element => {
            console.log(element);
        })
    }
}

export default GameForm;