import React from "react";

class BudgetMahjong extends React.Component {

    constructor() {
        super();
        this.state = {
            round: 0,
            repeats: 0
        }
    }

    render() {
        return (
            <div>
                <p id="roundIndicator">{this.roundString()}</p>
                <input type="number" className="player-points" defaultValue="25000" id="player-1-points"></input>
                <input type="number" className="player-points" defaultValue="25000" id="player-2-points"></input>
                <input type="number" className="player-points" defaultValue="25000" id="player-3-points"></input>
                <input type="number" className="player-points" defaultValue="25000" id="player-4-points"></input>

                <button className="roundButton" onClick={() => { this.nextRound(); this.updateIndicator(); this.validatePointSum()}}>next round</button>
                <button className="repeatButton" onClick={() => { this.repeatRound(); this.updateIndicator(); this.validatePointSum()}}>Repeat round</button>
                <p id="pointErrorText" hidden>Point sum error</p>
            </div>
        )
    }

    validatePointSum() {
        let errorTextElement = document.getElementById("pointErrorText");
        errorTextElement.hidden=true
        let sum = 0;

        for (let ind = 0; ind < document.getElementsByClassName("player-points").length; ind++) {
            sum += parseInt(document.getElementsByClassName("player-points").item(ind).value)
        }

        if (sum !== 100000+(this.state.repeats*300)) {
            let errorTextElement = document.getElementById("pointErrorText");

            errorTextElement.hidden = false;
        } 
    }

    nextRound() {
        this.state.round++;
        console.log(this.state);
    }

    repeatRound() {
        this.state.repeats++;
        console.log(this.state);
    }

    updateIndicator(newText) {
        if (newText === undefined) {
            newText = this.roundString();
        }

        document.getElementById("roundIndicator").innerText = newText;
    }

    roundString() {
        let roundString = "";

        if (this.state.round / 4 < 1) {
            roundString += "East "
        } else if (this.state.round / 4 < 2) {
            roundString += "South "
        } else {
            return "Game over"
        }

        switch (this.state.round % 4) {
            case 0:
                roundString += "1"
                break;
            case 1:
                roundString += "2"
                break;
            case 2:
                roundString += "3"
                break;
            case 3:
                roundString += "4"
                break;
            default:
                alert("Error making string");
                break;
        }
        return roundString;
    }
}

export default BudgetMahjong;