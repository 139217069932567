import React from "react";
class Home extends React.Component {

    render() {
        return (
            <>
                peepeepoopoo
            </>
        )
    }
}

export default Home;