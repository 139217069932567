import React from "react";
class Api extends React.Component{
    render() {
        return (
            <p>
                peepee
            </p>
        )
    }
}

export default Api;