import React from "react";
import MahjongTable from "./MahjongTable";
import GameForm from "./GameForm";
import BudgetMahjong from "./BudgetMahjong";

class Mahjong extends React.Component {

    constructor() {
        super();
        this.handler = this.handler.bind(this);
        this.state = {
            players: {
                player1: { name: "1", points: 25000},
                player2: { name: "2", points: 25000},
                player3: { name: "3", points: 25000},
                player4: { name: "4", points: 25000},
                dealer: 1
            }
        }
    }

    handler(newPlayers) {
        this.setState({
            players: newPlayers
        })
    }

    render() {
        return (
            <>
                <GameForm poster={this.handler} players = {this.state.players}/>
                <MahjongTable players={this.state.players} />
                <BudgetMahjong />
            </>
        )
    }
}

export default Mahjong;